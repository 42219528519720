import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import CurrencyTextField from 'components/CurrencyTextField';
import NumberTextField from 'components/NumberTextField';
import { REIMBURSEMENT_RATIO_PREMIUM_DEFAULT } from 'pages/pathwayBlueprint/PathwayModal';
import React from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities/index';

type IAgeBandCoverageLevelInputsProps = {
    ageBandsOnChange?: (arg0: string, arg1: number) => void;
    errors?: string[] | undefined;
    groupLevelAbbrev: string;
    reimbursementRatio: string;
    setReimbursementRatio?: React.Dispatch<React.SetStateAction<string>>;
    setSixtyFourReimbursement?: React.Dispatch<React.SetStateAction<string>>;
    shouldDisable?: boolean;
    sixtyFourReimbursement: string;
};

const RATIO_MIN = 1.0;
const RATIO_INCREMENT = 0.1;

const AgeBandCoverageLevelInputs = ({
    ageBandsOnChange,
    errors,
    groupLevelAbbrev,
    reimbursementRatio,
    setReimbursementRatio,
    setSixtyFourReimbursement,
    sixtyFourReimbursement,
    shouldDisable = false,
}: IAgeBandCoverageLevelInputsProps) => {
    const ratioMax = Number(REIMBURSEMENT_RATIO_PREMIUM_DEFAULT);
    const age64Name = groupLevelAbbrev.toLowerCase() + '64';
    const ratioName = groupLevelAbbrev.toLowerCase() + 'Ratio';

    const increaseRatio = () => {
        const ratio = +reimbursementRatio;
        const newRatio = Math.min(ratioMax, +(ratio + RATIO_INCREMENT).toFixed(1));
        setValues(newRatio);
    };

    const decreaseRatio = () => {
        const ratio = +reimbursementRatio;
        const newRatio = Math.max(RATIO_MIN, +(ratio - RATIO_INCREMENT).toFixed(1));
        setValues(newRatio);
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (hasValue(ageBandsOnChange)) {
            ageBandsOnChange(age64Name, Number(e.target.value));
        } else {
            setSixtyFourReimbursement?.(e.target.value);
        }
    };

    const setValues = (newRatio: number) => {
        if (hasValue(ageBandsOnChange)) {
            ageBandsOnChange(ratioName, newRatio);
        } else {
            setReimbursementRatio?.(newRatio.toString());
        }
    };

    return (
        <Stack alignItems="center" direction="row" spacing={1}>
            <CurrencyTextField
                data-cy={`sixty-four-${groupLevelAbbrev.toLowerCase()}`}
                decimalScale={2}
                disabled={shouldDisable}
                errors={errors}
                label={`64+ ${groupLevelAbbrev.toUpperCase()}`}
                name={`sixtyFour${groupLevelAbbrev}`}
                onChange={(e) => handleOnChange(e)}
                placeholder={groupLevelAbbrev.toUpperCase()}
                sx={{ width: '50%' }}
                value={sixtyFourReimbursement}
            />
            <NumberTextField
                data-cy={`${groupLevelAbbrev.toLowerCase()}-reimbursement-ratio`}
                decimalScale={1}
                disabled
                fixedDecimalScale
                label={`${groupLevelAbbrev.toUpperCase()} ratio`}
                name="eeReimbursementRatio"
                sx={{ width: '40%' }}
                value={reimbursementRatio}
            />
            <Stack sx={{ width: '10%' }}>
                <IconButton
                    aria-label="increase ratio"
                    disabled={+reimbursementRatio === ratioMax || shouldDisable}
                    onClick={increaseRatio}
                    sx={{ m: 0, minHeight: 0, minWidth: 0, p: 0 }}
                >
                    <ArrowDropUp fontSize="medium" />
                </IconButton>
                <IconButton
                    aria-label="decrease ratio"
                    disabled={+reimbursementRatio === RATIO_MIN || shouldDisable}
                    onClick={decreaseRatio}
                    sx={{ m: 0, minHeight: 0, minWidth: 0, p: 0 }}
                >
                    <ArrowDropDown fontSize="medium" />
                </IconButton>
            </Stack>
        </Stack>
    );
};

export default hot(module)(AgeBandCoverageLevelInputs);
