import { SYNC_USERS_WITH_ADP, syncUsersWithAdp } from 'actions/adp/syncUsers';
import { SYNC_USERS_WITH_FINCH, syncUsersWithFinch } from 'actions/finch/syncUsers';
import { SYNC_USERS_WITH_PAYLOCITY, syncUsersWithPaylocity } from 'actions/paylocity/syncUsers';
import { getTeamMembersStats } from 'actions/stats/getTeamMembersStats';
import {
    UPDATE_WAIVING_TEAM_MEMBERS_ACTION,
    updateWaivingMembers,
} from 'actions/team/updateWaivingMembers';
import { copyHouseholdInfo } from 'actions/user/copyHouseholdInfo';
import { downloadBulkCensusUpdate } from 'api/downloadBulkCensusUpdate';
import { downloadTeamMembers } from 'api/downloadTeamMembers';
import {
    AdpIntegrationStatuses,
    FinchIntegrationStatuses,
    HrsIntegrationProviders,
    PaylocityIntegrationStatuses,
    TeamStateIds,
} from 'api/generated/enums';
import {
    CensusUpload,
    CreateUser,
    EditPayrollIntegrationConfiguration,
    EditSubmittedExpense,
    EditSurveyTypeToSend,
    EditTeamAdmins,
    EditUserRoles,
    SendSurveys,
} from 'api/generated/permissions';
import ActionButtons, { IActionButtonItems } from 'components/ActionButtons';
import ConfirmationModal from 'components/ConfirmationModal';
import CopyDataYearToYearModal from 'components/CopyDataYearToYearModal';
import SendSurveyModal from 'components/SendSurveyModal';
import InviteMembersModal from 'components/teamMembersActionButtons/InviteMembersModal';
import SendInvitesModal from 'components/teamMembersActionButtons/SendInvitesModal';
import SendRenewalInvitesModal from 'components/teamMembersActionButtons/SendRenewalInvitesModal';
import SetAllowRecurringExpensesForTeamModal from 'components/teamMembersActionButtons/SetAllowRecurringExpensesForTeamModal';
import SetAllowShoppingForTeamModal from 'components/teamMembersActionButtons/SetAllowShoppingForTeamModal';
import SetSurveyTypeToSendForTeamModal from 'components/teamMembersActionButtons/SetSurveyTypeToSendForTeamModal';
import SetUsePremiumTaxCreditsForTeamModal from 'components/teamMembersActionButtons/SetUsePremiumTaxCreditsForTeamModal';
import UploadCensusModal from 'components/teamMembersActionButtons/UploadCensusModal';
import { push } from 'connected-react-router';
import { teamStateIds } from 'constants/teamStateIds';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useModalState from 'hooks/useModalState';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import {
    PEOPLE_ADD_PATH,
    PEOPLE_PATH,
    TEAMS_PEOPLE_ADD_PATH,
    TEAMS_PEOPLE_PATH,
} from 'routers/routes';
import { hasSomePermissions, ITeamProps } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isAdvisorSelector, isRhFullAdminSelector, isRhSelector } from 'selectors/role';
import { getEnableSendInvitesButton } from 'selectors/teamMembers';
import { getEnumValueByIndex, isHandledByCustomerSuccess } from 'utilities';
import { downloadFileFromApi } from 'utilities/rhFile';

const teamMembersActionButtonsSelector = (
    queryParams: URLSearchParams,
    isTeamManagementPage: boolean | undefined,
    teamProps: ITeamProps
) => (state: AppStore) => {
    const { teamId, team } = teamProps;
    const props = {
        addMemberPath: PEOPLE_ADD_PATH,
        canDownloadBulkEditCensus:
            hasSomePermissions(state, CensusUpload) &&
            ![TeamStateIds.MemberLaunch, TeamStateIds.Customer, teamStateIds.Renewing].includes(
                team?.teamStateId as TeamStateIds
            ),
        canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
        canEditSurveyTypeToSend: hasSomePermissions(state, EditSurveyTypeToSend),
        canInviteAdmin: hasSomePermissions(state, EditTeamAdmins, EditUserRoles),
        enableCensusImport: hasSomePermissions(state, CensusUpload),
        enableCreateMembersButton: hasSomePermissions(state, CreateUser),
        enableHrsSync: hasSomePermissions(state, EditPayrollIntegrationConfiguration),
        enableInviteMembersButton: isRhFullAdminSelector(state) && !isTeamManagementPage,
        enableSendInvitesButton: getEnableSendInvitesButton(state, teamProps),
        enableSendSurveysButton: hasSomePermissions(state, SendSurveys),
        hrsIntegrationProvider: teamProps.hrsIntegrationProvider,
        importCensus: `${PEOPLE_PATH}?import`,
        inviteAdminPath: `${PEOPLE_PATH}?inviteAdmin`,
        inviteAdvisorPath: `${PEOPLE_PATH}?inviteAdvisor`,
        inviteMembersPath: `${PEOPLE_PATH}?invite`,
        isLoadingHrsSync: hasApiActivity(
            state,
            SYNC_USERS_WITH_ADP,
            SYNC_USERS_WITH_PAYLOCITY,
            SYNC_USERS_WITH_FINCH
        ),
        isLoadingUpdateWaivingMembers: hasApiActivity(state, UPDATE_WAIVING_TEAM_MEMBERS_ACTION),
        isRh: isRhSelector(state),
        isRhOrAdvisor: isAdvisorSelector(state) || isRhSelector(state),
        peopleUrl: PEOPLE_PATH,
        selectedYear: +state.profileState.selectedYear,
        showInviteAdmin: queryParams.has('inviteAdmin'),
        showInviteAdvisor: queryParams.has('inviteAdvisor'),
        showInviteMembersModal: queryParams.has('invite'),
        showUploadCensusModal: queryParams.has('import'),
        userProfiles: state.userProfiles,
    };
    if (isTeamManagementPage) {
        props.peopleUrl = TEAMS_PEOPLE_PATH.replace(':teamId', teamId);
        props.inviteMembersPath = `${TEAMS_PEOPLE_PATH.replace(':teamId', teamId)}?invite`;
        props.importCensus = `${TEAMS_PEOPLE_PATH.replace(':teamId', teamId)}?import`;
        props.addMemberPath = TEAMS_PEOPLE_ADD_PATH.replace(':teamId', teamId);
        props.inviteAdminPath = `${TEAMS_PEOPLE_PATH.replace(':teamId', teamId)}?inviteAdmin`;
        props.inviteAdvisorPath = `${TEAMS_PEOPLE_PATH.replace(':teamId', teamId)}?inviteAdvisor`;
    }

    return props;
};

const TeamMembersActionButtons = () => {
    const dispatch = useThunkDispatch();
    const [queryParams] = useQuery();
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const teamProps = useTeamProps();
    const {
        teamId,
        teamStateId,
        isAdvisor: teamIsAdvisementTeam,
        adpIntegrationStatus,
        paylocityIntegrationStatus,
        finchIntegrationStatus,
    } = teamProps;
    const {
        addMemberPath,
        canDownloadBulkEditCensus,
        canEditSubmittedExpense,
        canEditSurveyTypeToSend,
        canInviteAdmin,
        enableHrsSync,
        enableCensusImport,
        enableCreateMembersButton,
        enableInviteMembersButton,
        enableSendInvitesButton,
        enableSendSurveysButton,
        hrsIntegrationProvider,
        importCensus,
        inviteAdminPath,
        inviteAdvisorPath,
        inviteMembersPath,
        isLoadingHrsSync,
        isLoadingUpdateWaivingMembers,
        isRh,
        isRhOrAdvisor,
        peopleUrl,
        selectedYear,
        showInviteAdmin,
        showInviteAdvisor,
        showInviteMembersModal,
        showUploadCensusModal,
    } = useSelector(teamMembersActionButtonsSelector(queryParams, isTeamManagementPage, teamProps));

    const [isSendInvitesModalVisible, setIsSendInvitesModalVisible] = useState(false);
    const [isBulkRenewalInviteModalVisible, setIsBulkRenewalInviteModalVisible] = useState(false);
    const [
        isSendSurveyConfirmationModalVisible,
        setIsSendSurveyConfirmationModalVisible,
    ] = useState(false);
    const [showCopyHouseholdDataModal, setShowCopyHouseholdDataModal] = useState(false);
    const [isDownloadingRoster, setIsDownloadingRoster] = useState(false);
    const [isDownloadingCensus, setIsDownloadingCensus] = useState(false);
    const {
        closeModal: closeHrsSyncModal,
        isVisible: isHrsSyncModalVisible,
        openModal: openHrsSyncModal,
    } = useModalState();
    const {
        closeModal: closeSetAllowShoppingModal,
        isVisible: isSetAllowShoppingModalVisible,
        openModal: openSetAllowShoppingModal,
    } = useModalState();
    const {
        closeModal: closeSetUsePremiumTaxCreditsModal,
        isVisible: isSetUsePremiumTaxCreditsModalVisible,
        openModal: openSetUsePremiumTaxCreditsModal,
    } = useModalState();
    const {
        closeModal: closeUpdateWaivingMembersModal,
        isVisible: isUpdateWaivingMembersModalVisible,
        openModal: openUpdateWaivingMembersModal,
    } = useModalState();
    const {
        closeModal: closeSetAllowRecurringExpensesModal,
        isVisible: isSetAllowRecurringExpensesModalVisible,
        openModal: openSetAllowRecurringExpensesModal,
    } = useModalState();
    const {
        closeModal: closeSetSurveyTypeToSendModal,
        isVisible: isSetSurveyTypeToSendModalVisible,
        openModal: openSetSurveyTypeToSendModal,
    } = useModalState();

    const handleModalClose = useCallback(() => {
        dispatch(push(peopleUrl));
    }, [dispatch, peopleUrl]);
    const hideSendInvitesConfirmationModal = useCallback(
        () => setIsSendInvitesModalVisible(false),
        []
    );
    const hrsIntegrationProviderName = getEnumValueByIndex(
        HrsIntegrationProviders,
        hrsIntegrationProvider
    );
    const onYesClickSyncWithHrs = useCallback(async () => {
        closeHrsSyncModal();
        hrsIntegrationProvider === HrsIntegrationProviders.Adp &&
            (await dispatch(syncUsersWithAdp(teamId)));
        hrsIntegrationProvider === HrsIntegrationProviders.Paylocity &&
            (await dispatch(syncUsersWithPaylocity(teamId)));
        hrsIntegrationProvider === HrsIntegrationProviders.Finch &&
            (await dispatch(syncUsersWithFinch(teamId)));
    }, [closeHrsSyncModal, dispatch, teamId, hrsIntegrationProvider]);
    const onYesClickSendInvites = useCallback(() => {
        hideSendInvitesConfirmationModal();
        if (!isTeamManagementPage && selectedYear > 0) {
            dispatch(getTeamMembersStats(teamId, selectedYear));
        }
    }, [hideSendInvitesConfirmationModal, isTeamManagementPage, dispatch, teamId, selectedYear]);
    const hideBulkSendRenewalInviteModal = useCallback(
        () => setIsBulkRenewalInviteModalVisible(false),
        []
    );
    const closeCopyHouseholdDataModal = useCallback(() => setShowCopyHouseholdDataModal(false), []);
    const onSendSurveyModalHide = useCallback(
        () => setIsSendSurveyConfirmationModalVisible(false),
        []
    );
    const onYesClickCopyOperationsInfo = useCallback(
        async (sourceYear, targetYear) =>
            await dispatch(copyHouseholdInfo(teamId, sourceYear, targetYear)),
        [dispatch, teamId]
    );
    const onYesClickUpdateWaivingMembers = useCallback(async () => {
        await dispatch(updateWaivingMembers(teamId));
        closeUpdateWaivingMembersModal();
    }, [closeUpdateWaivingMembersModal, dispatch, teamId]);

    const items = useMemo(
        () =>
            [
                {
                    isVisible: enableInviteMembersButton,
                    link: { to: inviteMembersPath },
                    text: 'Invite Members',
                },
                {
                    dataCy: 'add-member',
                    isVisible: enableCreateMembersButton,
                    link: { to: addMemberPath },
                    text: 'Add Member',
                },
                {
                    dataCy: 'invite-advisor',
                    isVisible: teamIsAdvisementTeam && isRh,
                    link: { to: inviteAdvisorPath },
                    text: 'Invite Advisor',
                },
                {
                    dataCy: 'invite-admin',
                    isVisible: canInviteAdmin,
                    link: { to: inviteAdminPath },
                    text: 'Invite Admin',
                },
                {
                    dataCy: 'import-button',
                    isVisible: enableCensusImport,
                    link: { to: importCensus },
                    text: 'Import',
                },
                {
                    dataCy: 'download-bulk-update-census-button',
                    isLoading: isDownloadingCensus,
                    isVisible: canDownloadBulkEditCensus,
                    onClick: async () => {
                        setIsDownloadingCensus(true);
                        await downloadFileFromApi(async () =>
                            downloadBulkCensusUpdate(teamId, selectedYear)
                        );
                        setIsDownloadingCensus(false);
                    },
                    text: 'Download Bulk Update Census',
                },
                {
                    dataCy: 'sync-with-adp',
                    isVisible:
                        enableHrsSync &&
                        adpIntegrationStatus === AdpIntegrationStatuses.Connected &&
                        hrsIntegrationProvider === HrsIntegrationProviders.Adp,
                    onClick: () => openHrsSyncModal(),
                    text: 'Sync with ADP',
                },
                {
                    dataCy: 'sync-with-finch',
                    isVisible:
                        enableHrsSync &&
                        finchIntegrationStatus === FinchIntegrationStatuses.Connected &&
                        hrsIntegrationProvider === HrsIntegrationProviders.Finch,
                    onClick: () => openHrsSyncModal(),
                    text: 'Sync with Finch',
                },
                {
                    dataCy: 'sync-with-paylocity',
                    isVisible:
                        enableHrsSync &&
                        paylocityIntegrationStatus === PaylocityIntegrationStatuses.Connected &&
                        hrsIntegrationProvider === HrsIntegrationProviders.Paylocity,
                    onClick: () => openHrsSyncModal(),
                    text: 'Sync with Paylocity',
                },
                {
                    dataCy: 'download-button',
                    isLoading: isDownloadingRoster,
                    isVisible: true,
                    onClick: async () => {
                        setIsDownloadingRoster(true);
                        await downloadFileFromApi(async () =>
                            downloadTeamMembers(teamId, selectedYear)
                        );
                        setIsDownloadingRoster(false);
                    },
                    text: 'Download Team Member Roster',
                },
                {
                    dataCy: 'send-surveys',
                    isVisible: enableSendSurveysButton,
                    onClick: () => setIsSendSurveyConfirmationModalVisible(true),
                    text: 'Send Surveys',
                    variant: 'warning',
                },
                {
                    dataCy: 'send-invites',
                    isVisible: enableSendInvitesButton,
                    onClick: () => setIsSendInvitesModalVisible(true),
                    text: 'Send Invites',
                },
                {
                    dataCy: 'copy-household-data',
                    isVisible: isRh,
                    onClick: () => setShowCopyHouseholdDataModal(true),
                    text: 'Copy Household Data',
                },
                {
                    dataCy: 'send-renewal',
                    isVisible: isRh && teamStateId === TeamStateIds.Renewing,
                    onClick: () => setIsBulkRenewalInviteModalVisible(true),
                    text: 'Send Renewal Invites',
                },
                {
                    dataCy: 'set-allow-shopping',
                    isVisible: isRhOrAdvisor,
                    onClick: () => openSetAllowShoppingModal(),
                    text: 'Set Allow Shopping',
                },
                {
                    dataCy: 'set-use-premium-tax-credits',
                    isVisible: isRhOrAdvisor,
                    onClick: () => openSetUsePremiumTaxCreditsModal(),
                    text: 'Set Use Premium Tax Credits',
                },
                {
                    dataCy: 'set-allow-recurring-expenses',
                    isVisible: canEditSubmittedExpense,
                    onClick: () => openSetAllowRecurringExpensesModal(),
                    text: 'Set Allow Recurring Expenses',
                },
                {
                    dataCy: 'set-survey-type-to-send',
                    isVisible: canEditSurveyTypeToSend,
                    onClick: () => openSetSurveyTypeToSendModal(),
                    text: 'Set Survey Type To Send',
                },
                {
                    isVisible: isRhOrAdvisor && teamStateId === TeamStateIds.Renewing,
                    onClick: () => openUpdateWaivingMembersModal(),
                    text: 'Update Waiving Members',
                },
            ] as IActionButtonItems,
        [
            addMemberPath,
            adpIntegrationStatus,
            canDownloadBulkEditCensus,
            canEditSubmittedExpense,
            canEditSurveyTypeToSend,
            canInviteAdmin,
            enableHrsSync,
            enableCensusImport,
            enableCreateMembersButton,
            enableInviteMembersButton,
            enableSendInvitesButton,
            enableSendSurveysButton,
            finchIntegrationStatus,
            importCensus,
            inviteAdminPath,
            inviteAdvisorPath,
            inviteMembersPath,
            isDownloadingCensus,
            isDownloadingRoster,
            isRh,
            isRhOrAdvisor,
            openHrsSyncModal,
            openSetAllowRecurringExpensesModal,
            openSetAllowShoppingModal,
            openSetSurveyTypeToSendModal,
            openSetUsePremiumTaxCreditsModal,
            openUpdateWaivingMembersModal,
            paylocityIntegrationStatus,
            selectedYear,
            teamId,
            teamIsAdvisementTeam,
            teamStateId,
            hrsIntegrationProvider,
        ]
    );

    const updateWaivingMembersModalBody = (
        <ul>
            <li>
                Active members that chose to waive for the member&apos;s active year will have their{' '}
                <strong>Member Status</strong> updated to <strong>Waived</strong>.
            </li>
            <li>
                Members that were previously waived that we have not heard from will have their{' '}
                <strong>Enrollment Status</strong> updated to <strong>Waived</strong>.
            </li>
        </ul>
    );

    return (
        <React.Fragment>
            {isSendSurveyConfirmationModalVisible && (
                <SendSurveyModal onHide={onSendSurveyModalHide} />
            )}
            {showCopyHouseholdDataModal && (
                <CopyDataYearToYearModal
                    modalText="Household Data"
                    onClose={closeCopyHouseholdDataModal}
                    onYesClick={onYesClickCopyOperationsInfo}
                />
            )}
            {(showInviteMembersModal || showInviteAdmin || showInviteAdvisor) && (
                <InviteMembersModal
                    handleClose={handleModalClose}
                    invitingAdmin={showInviteAdmin}
                    invitingAdvisor={showInviteAdvisor}
                    peopleUrl={peopleUrl}
                    teamId={teamId}
                />
            )}
            {showUploadCensusModal && (
                <UploadCensusModal onClose={handleModalClose} peopleUrl={peopleUrl} />
            )}
            {isHrsSyncModalVisible && (
                <ConfirmationModal
                    body={`Syncing with ${hrsIntegrationProviderName} can take several minutes and will override Remodel
                            Health member data with ${hrsIntegrationProviderName} employee data including Name, Phone,
                            DOB, Address, and Income. Upon completion, an email with a list of
                            members that were modified will be sent to ${
                                isHandledByCustomerSuccess(teamStateId)
                                    ? 'Customer Success'
                                    : 'Launch'
                            }.`}
                    noButtonText="Cancel"
                    onNoClick={closeHrsSyncModal}
                    onYesClick={onYesClickSyncWithHrs}
                    showActivity={isLoadingHrsSync}
                    title={`Sync with ${hrsIntegrationProviderName}`}
                    yesButtonText="Sync"
                />
            )}
            {isUpdateWaivingMembersModalVisible && (
                <ConfirmationModal
                    body={updateWaivingMembersModalBody}
                    onNoClick={closeUpdateWaivingMembersModal}
                    onYesClick={onYesClickUpdateWaivingMembers}
                    showActivity={isLoadingUpdateWaivingMembers}
                    title="Are you sure?"
                />
            )}
            {isBulkRenewalInviteModalVisible && (
                <SendRenewalInvitesModal onClose={hideBulkSendRenewalInviteModal} />
            )}
            {isSendInvitesModalVisible && (
                <SendInvitesModal
                    close={hideSendInvitesConfirmationModal}
                    onYesClick={onYesClickSendInvites}
                />
            )}
            {isSetAllowShoppingModalVisible && (
                <SetAllowShoppingForTeamModal close={closeSetAllowShoppingModal} />
            )}
            {isSetUsePremiumTaxCreditsModalVisible && (
                <SetUsePremiumTaxCreditsForTeamModal close={closeSetUsePremiumTaxCreditsModal} />
            )}
            {isSetAllowRecurringExpensesModalVisible && (
                <SetAllowRecurringExpensesForTeamModal
                    close={closeSetAllowRecurringExpensesModal}
                />
            )}
            {isSetSurveyTypeToSendModalVisible && (
                <SetSurveyTypeToSendForTeamModal close={closeSetSurveyTypeToSendModal} />
            )}
            <ActionButtons
                ButtonProps={{
                    xsClassName: 'mb-2',
                }}
                items={items}
            />
        </React.Fragment>
    );
};

export default hot(module)(withRouter(TeamMembersActionButtons));
