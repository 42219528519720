import { SYNC_USER_WITH_ADP, syncUserWithAdp } from 'actions/adp/syncUser';
import { SYNC_USER_WITH_FINCH, syncUserWithFinch } from 'actions/finch/syncUser';
import {
    GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION,
    getPathwayBlueprintDataForUser,
} from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { getTaxData } from 'actions/taxData/getTaxData';
import { getTokenForUser } from 'actions/token/getTokenForUser';
import { TOKEN_ACTION } from 'actions/token/tokenActionUtility';
import {
    CREATE_RESET_REQUIRED_LOGIN_ACTION,
    createResetRequiredLogin,
} from 'actions/user/CreateResetRequiredLogin';
import { ADD_USER_ROLE_ACTION, addUserRole } from 'actions/user/addUserRole';
import { copyHouseholdInfo } from 'actions/user/copyHouseholdInfo';
import { DELETE_USER_ACTION, deleteUser } from 'actions/user/deleteUser';
import {
    IHouseholdSocialSecurityRequests,
    editSocialSecurityNumbers,
} from 'actions/user/editSocialSecurityNumbers';
import { getHousehold } from 'actions/user/getHousehold';
import { GET_USER_BUDGET_ACTION, getUserBudget } from 'actions/user/getUserBudget';
import { GET_USER_PROFILE_ACTION, getUserProfile } from 'actions/user/getUserProfile';
import { listPagedUserProfiles } from 'actions/user/listPagedUserProfiles';
import { PATCH_MEMBER_QUOTE_INFO_ACTION } from 'actions/user/patchMemberQuoteInfo';
import { PATCH_MEMBER_VERIFIED_INFO_ACTION } from 'actions/user/patchMemberVerifiedInfo';
import { REMOVE_USER_ROLE_ACTION, removeUserRole } from 'actions/user/removeUserRole';
import {
    RESET_ACCOUNT_LOCKOUT_ACTION,
    resetAccountLockout,
} from 'actions/user/resetAccountLockout';
import { SEND_INVITES_ACTION, sendInvites } from 'actions/user/sendInvites';
import { SEND_RENEWAL_EMAIL_ACTION, sendRenewalEmail } from 'actions/user/sendRenewalEmail';
import {
    SET_ENROLLMENT_STATUS_TO_ENROLLED_ACTION,
    setEnrollmentStatusToEnrolled,
} from 'actions/user/setEnrollmentStatusToEnrolled';
import { SET_USER_STATUS_ACTION, setUserStatus } from 'actions/user/setUserStatus';
import {
    UPDATE_INCOME_VERIFIED_DATE_ACTION,
    updateIncomeVerifiedDate,
} from 'actions/user/updateIncomeVerifiedDate';
import {
    AdpIntegrationStatuses,
    EnrollmentStatuses,
    FinchIntegrationStatuses,
    HrsIntegrationProviders,
    TeamStateIds,
    UserStatus,
} from 'api/generated/enums';
import { IUser, IUserProfile } from 'api/generated/models';
import {
    DeactivateRhUser,
    DeleteUser,
    DeleteUsersForOwnTeam,
    EditPayrollIntegrationConfiguration,
    EditSocialSecurityNumber,
    EditTeamAdmins,
    EditUser,
    EditUserRoles,
    EditUserStatus,
    EditUserVerifiedInfo,
    ImpersonateUsers,
    InviteUsers,
    ResendRenewalInvite,
    ResetAccountLockout,
    SendSurveys,
    SetUserStatusInactive,
    SetUserStatusOffBoarding,
    SetUserStatusWaived,
    ViewSubmittedExpenses,
    ViewTaxData,
    ViewUserVerifiedInfo,
} from 'api/generated/permissions';
import { ROLE_IDS } from 'api/generated/roleIds';
import ActionButtons, { IActionButtonItems } from 'components/ActionButtons';
import ConfirmationModal from 'components/ConfirmationModal';
import CopyDataYearToYearModal from 'components/CopyDataYearToYearModal';
import EnterSocialSecurityModal from 'components/EnterSocialSecurityModal';
import InformationIconTooltip from 'components/InformationIconTooltip';
import SendSurveyModal from 'components/SendSurveyModal';
import CompareInfoModal from 'components/profileActionButtons/CompareInfoModal';
import CompleteEnrollmentModal from 'components/profileActionButtons/CompleteEnrollmentModal';
import CompleteIchraEnrollmentTasksModal from 'components/profileActionButtons/CompleteIchraEnrollmentTasksModal';
import InactiveConfirmationModal from 'components/profileActionButtons/InactiveConfirmationModal';
import OffBoardingConfirmationModal from 'components/profileActionButtons/OffBoardingConfirmationModal';
import OpenUpShoppingModal from 'components/profileActionButtons/OpenUpShoppingModal';
import UpdateIncomeVerifiedDateModal from 'components/profileActionButtons/UpdateIncomeVerifiedDateModal';
import { push } from 'connected-react-router';
import { RH_TEAM_CARE_EMAIL, RH_TEAM_ID } from 'constants/teams';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import {
    PEOPLE_EDIT_PATH,
    PEOPLE_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH,
    TEAMS_MEMBER_SURVEY_FOR_USER_PATH,
    TEAMS_PEOPLE_EDIT_PATH,
    TEAMS_PEOPLE_PATH,
} from 'routers/routes';
import { TokenService } from 'security/TokenService';
import { ITeamProps, hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import {
    isAdvisorSelector,
    isCcmAdminSelector,
    isRhSelector,
    isTeamAdminSelector,
    isUserInRoleSelector,
} from 'selectors/role';
import { enumToString, hasValue, isHandledByCustomerSuccess } from 'utilities';
import { isBasicInfoStatus, isVerifiedInfoStatus, userNeedsSurvey } from 'utilities/people';

const waivedConfirmationText = (user: IUser | undefined) => (
    <div>
        <p>
            You are about to set {user?.displayName}&apos;s status to &quot;Waived&quot;{' '}
            <InformationIconTooltip
                title={
                    <span data-cy="set-as-waived-tootlip">
                        <p>The following fields will be updated accordingly</p>

                        <p className="mb-0">
                            Member Status:{' '}
                            {user?.status === UserStatus.Renewing ? 'Active' : 'Waived'}
                        </p>
                        <p className="mb-0">Enrollment Status: Waived</p>
                        <p className="mb-0">Major Medical Coverage Level: Waived</p>
                    </span>
                }
            />{' '}
            for {user?.activeDate?.getYear()}. &quot;Waived&quot; is a unique status that affects
            your billing and payments processes. This is reserved for a member who is declining
            services from Remodel Health.
        </p>
        <p>A &quot;Waived&quot; member would meet the following criteria:</p>

        <ul>
            <li>They have no health benefits that Remodel Health helps administer</li>
            <li>They do not receive tele-health benefits via Remodel Health</li>
        </ul>
        <p>
            If you&apos;re not sure this member should be set to &quot;Waived&quot; you can cancel
            this step and reach out anytime to{' '}
            <a href={`mailto:${RH_TEAM_CARE_EMAIL}`}>{RH_TEAM_CARE_EMAIL}</a>.
        </p>
        <p>Otherwise you can click yes to update this member to the &quot;Waived&quot; status.</p>
    </div>
);

const isInANonWaivedStatus = (
    status: UserStatus | undefined,
    enrollmentStatusValue?: EnrollmentStatuses
) => status !== UserStatus.Waived || enrollmentStatusValue !== EnrollmentStatuses.Waived;

const profileActionButtonsSelector = (
    profile: IUserProfile,
    {
        adpIntegrationStatus,
        finchIntegrationStatus,
        hrsIntegrationProvider,
        isAdvisor,
        team,
        teamId,
        teamStateId,
        useIchraLaunchFlow,
    }: ITeamProps
) => (state: AppStore) => {
    const { status, userId } = profile.user ?? {};
    const enrollmentStatus = profile.yearlyUserInfo?.enrollmentStatus;
    const isUserProfileTeamAdmin = isUserInRoleSelector(state, userId, ROLE_IDS.TEAM_ADMIN);
    const isUserProfileTeamPayrollAdmin = isUserInRoleSelector(
        state,
        userId,
        ROLE_IDS.TEAM_PAYROLL_ADMIN
    );
    const isIchraPathway = profile?.activeWageUp?.wasSetFromIchraPathway === true;
    const isCurrentTeamAdmin = isTeamAdminSelector(state);
    const isNotTeamAdminSendingToVerifiedInfoMember = !(
        isCurrentTeamAdmin && isVerifiedInfoStatus(profile)
    );
    const isTeamStateMemberLaunchOrCustomerOrRenewing = [
        TeamStateIds.Customer,
        TeamStateIds.MemberLaunch,
        TeamStateIds.Renewing,
    ].includes(teamStateId);
    const isRh = isRhSelector(state);
    const canViewVerifiedInfo = hasSomePermissions(state, ViewUserVerifiedInfo);
    const isCurrent = state.current?.userProfile?.user?.userId === userId;
    const isRenewingOrWaived = [UserStatus.Renewing, UserStatus.Waived].contains(status);
    const isSendRenewalInviteVisible =
        [EnrollmentStatuses.ReadyForRenewal, EnrollmentStatuses.AdvisementNeeded].contains(
            enrollmentStatus?.value
        ) && isRenewingOrWaived;
    const isResendRenewalInviteVisible =
        [EnrollmentStatuses.InvitedToRenewal, EnrollmentStatuses.AdvisementNeeded].contains(
            enrollmentStatus?.value
        ) && isRenewingOrWaived;
    return {
        canViewVerifiedInfo,
        hrsIntegrationProvider,
        isCurrent,
        isRh,
        isUserProfileTeamAdmin,
        isUserProfileTeamPayrollAdmin,
        canAddQuoteInfo: hasSomePermissions(state, EditUser) && isBasicInfoStatus(profile),
        canCompleteEnrollment:
            !isBasicInfoStatus(profile) &&
            [UserStatus.Launching, UserStatus.Renewing].contains(status) &&
            enrollmentStatus?.value !== EnrollmentStatuses.Enrolled &&
            hasSomePermissions(state, EditUserStatus) &&
            hasValue(profile?.user?.activeDate),
        canCompleteIchraEnrollmentTasks:
            useIchraLaunchFlow &&
            isIchraPathway &&
            ![
                EnrollmentStatuses.Enrolled,
                EnrollmentStatuses.InvitedToLaunch,
                EnrollmentStatuses.InvitedToRenewal,
                EnrollmentStatuses.PendingApplication,
                EnrollmentStatuses.PendingDecision,
                EnrollmentStatuses.PendingWageUpRequest,
                EnrollmentStatuses.ReadyForLaunch,
                EnrollmentStatuses.ReadyForRenewal,
            ].contains(enrollmentStatus?.value),
        canCreateLogin:
            isRh && team?.isIchraExpress && isUserProfileTeamAdmin && !profile.hasLoginInfo,
        canDeleteUsers:
            hasSomePermissions(state, DeleteUser) &&
            (teamId !== state.current.userProfile.user?.teamId ||
                hasSomePermissions(state, DeleteUsersForOwnTeam)),
        canEditSsns: hasSomePermissions(state, EditSocialSecurityNumber),
        canEditTeamAdmins: hasSomePermissions(state, EditTeamAdmins, EditUserRoles),
        canEditVerifiedInfo: hasSomePermissions(state, EditUserVerifiedInfo),
        canImpersonateUsers:
            hasSomePermissions(state, ImpersonateUsers) && !isCurrent && !isAdvisor,
        canInviteMembers:
            isTeamStateMemberLaunchOrCustomerOrRenewing && hasSomePermissions(state, InviteUsers),
        canResendRenewalInvite:
            hasSomePermissions(state, ResendRenewalInvite) &&
            teamStateId === TeamStateIds.Renewing &&
            isResendRenewalInviteVisible,
        canSendRenewalInvite:
            hasSomePermissions(state, InviteUsers) &&
            teamStateId === TeamStateIds.Renewing &&
            isSendRenewalInviteVisible,
        canSendSurveys:
            hasSomePermissions(state, SendSurveys) && isNotTeamAdminSendingToVerifiedInfoMember,
        canSetStatusEnrolled:
            isCcmAdminSelector(state) &&
            enrollmentStatus?.value !== EnrollmentStatuses.Enrolled &&
            isTeamStateMemberLaunchOrCustomerOrRenewing,
        canSetStatusInactive:
            hasSomePermissions(state, SetUserStatusInactive) && status === UserStatus.OffBoarding,
        canSetStatusInactiveForRhTeamMember:
            hasSomePermissions(state, DeactivateRhUser) &&
            teamId === RH_TEAM_ID &&
            status !== UserStatus.Inactive,
        canSetStatusOffBoarding:
            hasSomePermissions(state, SetUserStatusOffBoarding) &&
            status !== UserStatus.OffBoarding &&
            [TeamStateIds.Customer, TeamStateIds.Renewing].includes(teamStateId),
        canSetStatusReadyToShop:
            (isRh || isAdvisorSelector(state)) &&
            [UserStatus.Launching, UserStatus.Active, UserStatus.Waived].contains(status),
        canSetStatusWaived:
            hasSomePermissions(state, SetUserStatusWaived) &&
            isInANonWaivedStatus(status, enrollmentStatus?.value) &&
            isTeamStateMemberLaunchOrCustomerOrRenewing,
        canSyncMemberWithHrProvider:
            hasSomePermissions(state, EditPayrollIntegrationConfiguration) &&
            (adpIntegrationStatus === AdpIntegrationStatuses.Connected ||
                finchIntegrationStatus === FinchIntegrationStatuses.Connected),
        canViewSubmittedExpenses: hasSomePermissions(state, ViewSubmittedExpenses),
        canViewTaxData: hasSomePermissions(state, ViewTaxData),
        hrsProviderName: enumToString(HrsIntegrationProviders, hrsIntegrationProvider),
        isSendingInvite: hasApiActivity(state, SEND_INVITES_ACTION),
        isSendingRenewalInvite: hasApiActivity(state, SEND_RENEWAL_EMAIL_ACTION),
        isSyncingUser: hasApiActivity(state, SYNC_USER_WITH_ADP || SYNC_USER_WITH_FINCH),
        isUpdatingIncomeVerifiedDate: hasApiActivity(state, UPDATE_INCOME_VERIFIED_DATE_ACTION),
        peopleState: state.peopleState,
        selectedYear: +state.profileState.selectedYear,
        showAccountUnlockAction:
            profile.isLockedOut && hasSomePermissions(state, ResetAccountLockout),
        showActivity: hasApiActivity(
            state,
            TOKEN_ACTION,
            DELETE_USER_ACTION,
            SET_USER_STATUS_ACTION,
            PATCH_MEMBER_VERIFIED_INFO_ACTION,
            PATCH_MEMBER_QUOTE_INFO_ACTION,
            GET_USER_PROFILE_ACTION,
            GET_USER_BUDGET_ACTION,
            RESET_ACCOUNT_LOCKOUT_ACTION,
            GET_USER_PROFILE_ACTION,
            GET_USER_BUDGET_ACTION,
            GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION,
            SET_ENROLLMENT_STATUS_TO_ENROLLED_ACTION,
            CREATE_RESET_REQUIRED_LOGIN_ACTION,
            ADD_USER_ROLE_ACTION,
            DELETE_USER_ACTION,
            REMOVE_USER_ROLE_ACTION
        ),
    };
};
type IProfileActionButtonsProps = {
    profile: IUserProfile;
    shouldRedirect?: boolean;
};

const ProfileActionButtons = ({ profile, shouldRedirect }: IProfileActionButtonsProps) => {
    const { canOpenUpShopping, user } = profile;
    const userId = user?.userId;
    const dispatch = useThunkDispatch();
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const teamProps = useTeamProps();
    const { hasUserIdUrlParam } = useUserProps();
    const { teamId, teamStateId, useReimbursementProgramManagement } = teamProps;
    const {
        canAddQuoteInfo,
        canCompleteEnrollment,
        canCompleteIchraEnrollmentTasks,
        canCreateLogin,
        canDeleteUsers,
        canEditSsns,
        canEditTeamAdmins,
        canEditVerifiedInfo,
        canImpersonateUsers,
        canInviteMembers,
        canResendRenewalInvite,
        canSendRenewalInvite,
        canSendSurveys,
        canSetStatusEnrolled,
        canSetStatusInactive,
        canSetStatusInactiveForRhTeamMember,
        canSetStatusOffBoarding,
        canSetStatusWaived,
        canSyncMemberWithHrProvider,
        canViewSubmittedExpenses,
        canViewTaxData,
        canViewVerifiedInfo,
        hrsProviderName,
        hrsIntegrationProvider,
        isCurrent,
        isRh,
        isSendingInvite,
        isSendingRenewalInvite,
        isSyncingUser,
        isUpdatingIncomeVerifiedDate,
        isUserProfileTeamAdmin,
        isUserProfileTeamPayrollAdmin,
        peopleState,
        selectedYear,
        showActivity,
        showAccountUnlockAction,
    } = useSelector(profileActionButtonsSelector(profile, teamProps));
    const [
        isSendSurveyConfirmationModalVisible,
        setIsSendSurveyConfirmationModalVisible,
    ] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showOffBoardingConfirmModal, setShowOffBoardingConfirmModal] = useState(false);
    const [showCopyHouseholdDataModal, setShowCopyHouseholdDataModal] = useState(false);
    const [modalProps, setModalProps] = useState<{
        body: ReactNode;
        onYesClick: () => void;
    }>({
        body: '' as ReactNode,
        onYesClick: () => undefined,
    });
    const {
        isVisible: isIncomeVerifiedDateModalVisible,
        openModal: openIncomeVerifiedDateModal,
        closeModal: closeIncomeVerifiedDateModal,
    } = useModalState();
    const {
        isVisible: isCompareInfoModalVisible,
        openModal: openCompareInfoModal,
        closeModal: closeCompareInfoModal,
    } = useModalState();
    const {
        isVisible: isInactiveConfirmationModalVisible,
        openModal: openInactiveConfirmationModal,
        closeModal: closeInactiveConfirmationModal,
    } = useModalState();
    const {
        closeModal: closeEnterSocialSecurityModal,
        isVisible: isEnterSocialSecurityModalVisible,
        openModal: openEnterSocialSecurityModal,
    } = useModalState();
    const {
        closeModal: closeCompleteEnrollmentModal,
        isVisible: isCompleteEnrollmentModalVisible,
        openModal: openCompleteEnrollmentModal,
    } = useModalState();
    const {
        closeModal: closeCompleteIchraEnrollmentTasksModal,
        isVisible: isCompleteIchraEnrollmentTasksModalVisible,
        openModal: openCompleteIchraEnrollmentTasksModal,
    } = useModalState();
    const {
        closeModal: closeOpenUpShoppingConfirmationModal,
        isVisible: isOpenUpShoppingConfirmationModalVisible,
        openModal: openUpShoppingConfirmationModal,
    } = useModalState();
    const {
        closeModal: closeSyncUserWithHrProviderModal,
        isVisible: isSyncUserWithHrProviderModalVisible,
        openModal: openSyncUserWithHrProviderModal,
    } = useModalState();
    const onNoClick = useCallback(() => setShowConfirmModal(false), []);
    const navigateToTeamRoute = useCallback(
        (path) => {
            dispatch(push(generatePath(path, { teamId, userId })));
        },
        [dispatch, teamId, userId]
    );
    const onDeleteYesClick = useCallback(async () => {
        await dispatch(deleteUser(userId));
        if (shouldRedirect) {
            navigateToTeamRoute(TEAMS_PEOPLE_PATH);
        } else {
            onNoClick();
        }
    }, [dispatch, navigateToTeamRoute, onNoClick, shouldRedirect, userId]);
    const onSetStatusYesClick = useCallback(
        (
            userStatus: UserStatus,
            toastSuccessMessage: string,
            additionalFunction?: () => Promise<unknown>
        ) => async () => {
            await dispatch(
                setUserStatus(userId, userStatus, isCurrent, {
                    toastSuccessMessage,
                })
            );
            if (additionalFunction) {
                await additionalFunction();
            }
            onNoClick();
        },
        [dispatch, isCurrent, onNoClick, userId]
    );
    const onSetWaivedYesClick = onSetStatusYesClick(
        UserStatus.Waived,
        'Successfully set user to Waived status',
        async () => {
            if (hasUserIdUrlParam) {
                await dispatch(getUserProfile(userId, isCurrent, selectedYear));
                await dispatch(getUserBudget(userId, selectedYear));
                await dispatch(getPathwayBlueprintDataForUser(userId, selectedYear));
            } else {
                await dispatch(listPagedUserProfiles(teamId, selectedYear, peopleState));
            }
        }
    );
    const onSetEnrolledYesClick = useCallback(async () => {
        await dispatch(setEnrollmentStatusToEnrolled(userId));
        if (!hasUserIdUrlParam) {
            await dispatch(listPagedUserProfiles(teamId, selectedYear, peopleState));
        }
        onNoClick();
    }, [dispatch, hasUserIdUrlParam, onNoClick, peopleState, selectedYear, teamId, userId]);
    const onSendInvite = useCallback(async () => {
        await dispatch(sendInvites(user ? [user] : [], undefined, true));
        if (!hasUserIdUrlParam) {
            await dispatch(listPagedUserProfiles(teamId, selectedYear, peopleState));
        }
        onNoClick();
    }, [dispatch, hasUserIdUrlParam, onNoClick, peopleState, selectedYear, teamId, user]);
    const onSendRenewal = useCallback(async () => {
        await dispatch(sendRenewalEmail(user));
        if (!hasUserIdUrlParam) {
            await dispatch(listPagedUserProfiles(teamId, selectedYear, peopleState));
        }
        onNoClick();
    }, [dispatch, hasUserIdUrlParam, onNoClick, peopleState, selectedYear, teamId, user]);
    const showConfirmationModal = useCallback((body: ReactNode, onYesClick: () => void) => {
        setModalProps({ body, onYesClick });
        setShowConfirmModal(true);
    }, []);
    const closeCopyHouseholdDataModal = useCallback(() => setShowCopyHouseholdDataModal(false), []);
    const onSendSurveyModalHide = useCallback(
        () => setIsSendSurveyConfirmationModalVisible(false),
        []
    );
    const onYesClick = useCallback(
        async (sourceYear, targetYear) => {
            await dispatch(copyHouseholdInfo(teamId, sourceYear, targetYear, userId));
            if (canViewTaxData) {
                await dispatch(getTaxData(userId, targetYear));
            }
        },
        [canViewTaxData, dispatch, teamId, userId]
    );
    const onAddTeamAdminYesClick = useCallback(async () => {
        await dispatch(
            addUserRole(
                teamId,
                userId,
                ROLE_IDS.TEAM_ADMIN,
                `Set ${user?.displayName} as Team Admin`,
                [ROLE_IDS.TEAM_ADMIN, ROLE_IDS.TEAM_PAYROLL_ADMIN]
            )
        );
        onNoClick();
    }, [dispatch, teamId, userId, user?.displayName, onNoClick]);
    const onRemoveTeamAdminYesClick = useCallback(async () => {
        await dispatch(
            removeUserRole(
                teamId,
                userId,
                ROLE_IDS.TEAM_ADMIN,
                `Removed ${user?.displayName} as Team Admin`,
                [ROLE_IDS.TEAM_ADMIN, ROLE_IDS.TEAM_PAYROLL_ADMIN]
            )
        );
        onNoClick();
    }, [dispatch, teamId, userId, user?.displayName, onNoClick]);
    const onAddTeamPayrollAdminYesClick = useCallback(async () => {
        await dispatch(
            addUserRole(
                teamId,
                userId,
                ROLE_IDS.TEAM_PAYROLL_ADMIN,
                `Set ${user?.displayName} as Team Payroll Admin`,
                [ROLE_IDS.TEAM_ADMIN, ROLE_IDS.TEAM_PAYROLL_ADMIN]
            )
        );
        onNoClick();
    }, [dispatch, teamId, userId, user?.displayName, onNoClick]);
    const onRemoveTeamPayrollAdminYesClick = useCallback(async () => {
        await dispatch(
            removeUserRole(
                teamId,
                userId,
                ROLE_IDS.TEAM_PAYROLL_ADMIN,
                `Removed ${user?.displayName} as Team Payroll Admin`,
                [ROLE_IDS.TEAM_ADMIN, ROLE_IDS.TEAM_PAYROLL_ADMIN]
            )
        );
        onNoClick();
    }, [dispatch, teamId, userId, user?.displayName, onNoClick]);
    const onCreateLoginYesClick = useCallback(async () => {
        await dispatch(createResetRequiredLogin(userId, selectedYear));
        onNoClick();
    }, [dispatch, onNoClick, selectedYear, userId]);
    const onUpdateIncomeVerifiedDate = async () => {
        await dispatch(updateIncomeVerifiedDate(profile.memberVerifiedInfo?.memberVerifiedInfoId));
        closeIncomeVerifiedDateModal();
    };

    const addOrRemoveTeamAdminActions = useMemo(
        () => [
            {
                isVisible: canEditTeamAdmins && !isUserProfileTeamAdmin,
                onClick: () =>
                    showConfirmationModal(
                        `Are you sure you want to add ${user?.displayName} as a Team Admin?`,
                        onAddTeamAdminYesClick
                    ),
                text: 'Set Member as Team Admin',
            },
            {
                isVisible: canEditTeamAdmins && isUserProfileTeamAdmin,
                onClick: async () =>
                    showConfirmationModal(
                        `Are you sure you want to remove ${user?.displayName} as a Team Admin?`,
                        onRemoveTeamAdminYesClick
                    ),
                text: 'Remove Member as Team Admin',
            },
        ],
        [
            canEditTeamAdmins,
            isUserProfileTeamAdmin,
            onAddTeamAdminYesClick,
            onRemoveTeamAdminYesClick,
            showConfirmationModal,
            user?.displayName,
        ]
    );
    const addOrRemoveTeamPayrollAdminActions = useMemo(
        () => [
            {
                isVisible: canEditTeamAdmins && !isUserProfileTeamPayrollAdmin,
                onClick: () =>
                    showConfirmationModal(
                        `Are you sure you want to add ${user?.displayName} as a Team Payroll Admin?`,
                        onAddTeamPayrollAdminYesClick
                    ),
                text: 'Set Member as Team Payroll Admin',
            },
            {
                isVisible: canEditTeamAdmins && isUserProfileTeamPayrollAdmin,
                onClick: () =>
                    showConfirmationModal(
                        `Are you sure you want to remove ${user?.displayName} as a Team Payroll Admin?`,
                        onRemoveTeamPayrollAdminYesClick
                    ),
                text: 'Remove Member as Team Payroll Admin',
            },
        ],
        [
            canEditTeamAdmins,
            isUserProfileTeamPayrollAdmin,
            onAddTeamPayrollAdminYesClick,
            onRemoveTeamPayrollAdminYesClick,
            showConfirmationModal,
            user?.displayName,
        ]
    );

    const isImpersonateUserActionDisabled =
        TokenService.getHasExistingToken() || !profile.hasLoginInfo;
    const items = useMemo(
        () =>
            [
                {
                    isDisabled: isImpersonateUserActionDisabled,
                    isLoading: showActivity,
                    isVisible: canImpersonateUsers,
                    onClick: async () => dispatch(getTokenForUser(userId)),
                    text: 'Impersonate User',
                    tooltip:
                        'Cannot impersonate user if "Has Login" is "No" or you are already impersonating someone',
                },
                {
                    isLoading: showActivity,
                    isVisible: showAccountUnlockAction,
                    onClick: async () => dispatch(resetAccountLockout(userId, selectedYear)),
                    text: 'Unlock Account',
                },
                {
                    isVisible: canSetStatusWaived,
                    onClick: () =>
                        showConfirmationModal(waivedConfirmationText(user), onSetWaivedYesClick),
                    text: 'Set Member as Waived',
                },
                ...addOrRemoveTeamAdminActions,
                ...addOrRemoveTeamPayrollAdminActions,
                {
                    isVisible: canEditVerifiedInfo && profile.memberVerifiedInfo,
                    onClick: openIncomeVerifiedDateModal,
                    text: 'Update Income Verified Date',
                },
                {
                    isVisible: canAddQuoteInfo,
                    onClick: () =>
                        navigateToTeamRoute(
                            isTeamManagementPage ? TEAMS_PEOPLE_EDIT_PATH : PEOPLE_EDIT_PATH
                        ),
                    text: 'Add Quote Info',
                },
                {
                    dataCy: `send-survey-${user?.email}`,
                    isVisible: canSendSurveys,
                    onClick: () => setIsSendSurveyConfirmationModalVisible(true),
                    text: hasValue(user?.surveyLastSentDate) ? 'Resend Survey' : 'Send Survey',
                },
                {
                    isVisible: canEditVerifiedInfo && userNeedsSurvey(profile),
                    onClick: () => navigateToTeamRoute(TEAMS_MEMBER_SURVEY_FOR_USER_PATH),
                    text: 'Take Survey',
                },
                {
                    isLoading: isSendingInvite,
                    isVisible: canInviteMembers && !profile.hasLoginInfo,
                    onClick: onSendInvite,
                    text: 'Send Invite',
                },
                {
                    isLoading: isSendingRenewalInvite,
                    isVisible: canSendRenewalInvite,
                    onClick: onSendRenewal,
                    text: 'Send Renewal',
                },
                {
                    isLoading: isSendingRenewalInvite,
                    isVisible: !canSendRenewalInvite && canResendRenewalInvite,
                    onClick: onSendRenewal,
                    text: 'Resend Renewal',
                },
                {
                    isVisible: isRh,
                    onClick: () => setShowCopyHouseholdDataModal(true),
                    text: 'Copy Household Data',
                },
                {
                    isVisible: canViewVerifiedInfo,
                    onClick: openCompareInfoModal,
                    text: 'Compare Info',
                },
                {
                    isVisible: canSetStatusOffBoarding,
                    onClick: () => setShowOffBoardingConfirmModal(true),
                    text: 'Drop Member',
                },
                {
                    isVisible: canSetStatusInactive || canSetStatusInactiveForRhTeamMember,
                    onClick: openInactiveConfirmationModal,
                    text: 'Set Member as Inactive',
                },
                {
                    isVisible: canDeleteUsers,
                    onClick: () =>
                        showConfirmationModal(
                            `Are you absolutely sure you want to delete ${user?.displayName}? This action cannot be undone.`,
                            onDeleteYesClick
                        ),
                    text: 'Delete',
                    variant: 'outline-danger',
                },
                {
                    isVisible: (isCurrent || canEditSsns) && hasValue(profile.memberVerifiedInfo),
                    onClick: openEnterSocialSecurityModal,
                    text: 'Update SSNs',
                },
                {
                    isVisible: canOpenUpShopping,
                    onClick: openUpShoppingConfirmationModal,
                    text: 'Open Up Shopping',
                },
                {
                    isVisible: canSetStatusEnrolled && user?.activeDate?.getYear() === selectedYear,
                    onClick: () =>
                        showConfirmationModal(
                            `Are you sure you want to set this member's ${user?.activeDate?.getYear()} enrollment status to Enrolled?`,
                            onSetEnrolledYesClick
                        ),
                    text: 'Set Member as Enrolled',
                },
                {
                    isVisible: canCompleteIchraEnrollmentTasks && canCompleteEnrollment,
                    onClick: openCompleteIchraEnrollmentTasksModal,
                    text: 'Complete ICHRA Enrollment Tasks',
                },
                {
                    isVisible: canCompleteEnrollment,
                    onClick: openCompleteEnrollmentModal,
                    text: 'Complete Enrollment',
                },
                {
                    isLoading: isSyncingUser,
                    isVisible: canSyncMemberWithHrProvider,
                    onClick: openSyncUserWithHrProviderModal,
                    text: `${
                        user?.isHrsAssociateIdSet ? '' : 'Map & '
                    }Sync with ${hrsProviderName}`,
                },
                {
                    isVisible:
                        canViewSubmittedExpenses && useReimbursementProgramManagement && !isRh,
                    onClick: () =>
                        dispatch(
                            push(
                                generatePath(PEOPLE_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH, {
                                    userId,
                                })
                            )
                        ),
                    text: 'View Submitted Expenses',
                },
                {
                    isVisible: canCreateLogin,
                    onClick: () =>
                        showConfirmationModal(
                            'Are you sure you want to create a login for this ICHRA Express Admin? A Reset Password email will be sent to this member on confirmation.',
                            onCreateLoginYesClick
                        ),
                    text: 'Create Login',
                },
            ] as IActionButtonItems,
        [
            addOrRemoveTeamAdminActions,
            addOrRemoveTeamPayrollAdminActions,
            canAddQuoteInfo,
            canCompleteEnrollment,
            canCompleteIchraEnrollmentTasks,
            canCreateLogin,
            canDeleteUsers,
            canEditSsns,
            canEditVerifiedInfo,
            canImpersonateUsers,
            canInviteMembers,
            canOpenUpShopping,
            canResendRenewalInvite,
            canSendRenewalInvite,
            canSendSurveys,
            canSetStatusEnrolled,
            canSetStatusInactive,
            canSetStatusInactiveForRhTeamMember,
            canSetStatusOffBoarding,
            canSetStatusWaived,
            canSyncMemberWithHrProvider,
            canViewSubmittedExpenses,
            canViewVerifiedInfo,
            dispatch,
            hrsProviderName,
            isCurrent,
            isImpersonateUserActionDisabled,
            isRh,
            isSendingInvite,
            isSendingRenewalInvite,
            isSyncingUser,
            isTeamManagementPage,
            navigateToTeamRoute,
            onCreateLoginYesClick,
            onDeleteYesClick,
            onSendInvite,
            onSendRenewal,
            onSetEnrolledYesClick,
            onSetWaivedYesClick,
            openCompareInfoModal,
            openCompleteEnrollmentModal,
            openCompleteIchraEnrollmentTasksModal,
            openEnterSocialSecurityModal,
            openInactiveConfirmationModal,
            openIncomeVerifiedDateModal,
            openUpShoppingConfirmationModal,
            openSyncUserWithHrProviderModal,
            profile,
            selectedYear,
            showAccountUnlockAction,
            showActivity,
            showConfirmationModal,
            useReimbursementProgramManagement,
            user,
            userId,
        ]
    );
    const onOffBoardingNoClick = () => setShowOffBoardingConfirmModal(false);
    const saveSSNs = useCallback(
        async (
            socialSecurityRequests: IHouseholdSocialSecurityRequests,
            isEdit: boolean | undefined
        ) => {
            await dispatch(
                editSocialSecurityNumbers(userId, selectedYear, socialSecurityRequests, isEdit)
            );
            closeEnterSocialSecurityModal();
            await dispatch(getHousehold(userId, isCurrent, selectedYear));
        },
        [closeEnterSocialSecurityModal, dispatch, isCurrent, selectedYear, userId]
    );
    const onYesClickSyncWithHrProvider = useCallback(async () => {
        closeSyncUserWithHrProviderModal();
        if (hrsIntegrationProvider === HrsIntegrationProviders.Adp) {
            await dispatch(syncUserWithAdp(userId));
        }
        if (hrsIntegrationProvider === HrsIntegrationProviders.Finch) {
            await dispatch(syncUserWithFinch(userId));
        }
    }, [closeSyncUserWithHrProviderModal, dispatch, hrsIntegrationProvider, userId]);
    return (
        <React.Fragment>
            {showCopyHouseholdDataModal && (
                <CopyDataYearToYearModal
                    modalText="Household Data"
                    onClose={closeCopyHouseholdDataModal}
                    onYesClick={onYesClick}
                />
            )}
            {isSendSurveyConfirmationModalVisible && (
                <SendSurveyModal onHide={onSendSurveyModalHide} surveyUserId={userId} />
            )}
            {showOffBoardingConfirmModal && (
                <OffBoardingConfirmationModal
                    isCurrent={isCurrent}
                    onNoClick={onOffBoardingNoClick}
                    user={user}
                />
            )}
            {showConfirmModal && (
                <ConfirmationModal
                    body={modalProps.body}
                    onNoClick={onNoClick}
                    onYesClick={modalProps.onYesClick}
                    showActivity={showActivity}
                    title="Confirm"
                />
            )}
            {isIncomeVerifiedDateModalVisible && (
                <UpdateIncomeVerifiedDateModal
                    name={profile.user?.displayName}
                    onNoClick={closeIncomeVerifiedDateModal}
                    onYesClick={onUpdateIncomeVerifiedDate}
                    showActivity={isUpdatingIncomeVerifiedDate}
                    year={profile.memberVerifiedInfo?.year}
                />
            )}
            {isCompareInfoModalVisible && (
                <CompareInfoModal onClose={closeCompareInfoModal} user={user} />
            )}
            {isInactiveConfirmationModalVisible && (
                <InactiveConfirmationModal
                    isCurrent={isCurrent}
                    isRhMember={canSetStatusInactiveForRhTeamMember}
                    onClose={closeInactiveConfirmationModal}
                    user={user}
                />
            )}
            {isEnterSocialSecurityModalVisible && (
                <EnterSocialSecurityModal
                    includeEntireHousehold
                    onCancelClick={closeEnterSocialSecurityModal}
                    onHide={closeEnterSocialSecurityModal}
                    onSaveClick={saveSSNs}
                />
            )}
            {isCompleteIchraEnrollmentTasksModalVisible && (
                <CompleteIchraEnrollmentTasksModal
                    onClose={closeCompleteIchraEnrollmentTasksModal}
                    showActivity={showActivity}
                    user={profile.user}
                />
            )}
            {isCompleteEnrollmentModalVisible && (
                <CompleteEnrollmentModal
                    onClose={closeCompleteEnrollmentModal}
                    user={profile.user}
                />
            )}
            {isOpenUpShoppingConfirmationModalVisible && (
                <OpenUpShoppingModal
                    onClose={closeOpenUpShoppingConfirmationModal}
                    showActivity={showActivity}
                    userId={userId ?? ''}
                />
            )}
            {isSyncUserWithHrProviderModalVisible && (
                <ConfirmationModal
                    body={`Syncing with ${hrsProviderName} can take several minutes and will override Remodel
                        Health member data with ${hrsProviderName} employee data including Name, Phone,
                        DOB, Address, and Income. Upon completion, an email will be sent to ${
                            isHandledByCustomerSuccess(teamStateId) ? 'Customer Success' : 'Launch'
                        }.`}
                    noButtonText="Cancel"
                    onNoClick={closeSyncUserWithHrProviderModal}
                    onYesClick={onYesClickSyncWithHrProvider}
                    showActivity={isSyncingUser}
                    title={`Sync ${user?.displayName} with ${hrsProviderName}`}
                    yesButtonText="Sync"
                />
            )}
            <ActionButtons dataCy={`profile-actions-button-${user?.email}`} items={items} />
        </React.Fragment>
    );
};

export default hot(module)(ProfileActionButtons);
