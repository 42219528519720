import {
    EditSubmittedExpense,
    EditUserRoles,
    ViewPathwayBlueprints,
    ViewPayrollReports,
    ViewTeamBenefit,
    ViewTeamNotes,
    ViewTeamRhFiles,
} from 'api/generated/permissions';
import Tabs, { ITab } from 'components/authenticatedPageWrapper/Tabs';
import useExternalTeamProps from 'components/authenticatedPageWrapper/useExternalTeamProps';
import { TeamNavIndex } from 'constants/teamNavIndex';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import {
    TEAM_BENEFIT_MEMBER_MANAGEMENT_PATH,
    TEAMS_BENEFITS_PATH,
    TEAMS_DASHBOARD_PATH,
    TEAMS_HBA_PATH,
    TEAMS_INTEGRATIONS_PATH,
    TEAMS_NOTES_PATH,
    TEAMS_PATH,
    TEAMS_PATHWAY_BLUEPRINT_COST_COMPARISON_PATH,
    TEAMS_PATHWAY_BLUEPRINT_PATH,
    TEAMS_PATHWAY_BLUEPRINTS_PATH,
    TEAMS_PAYROLL_PATH,
    TEAMS_PEOPLE_ADD_PATH,
    TEAMS_PEOPLE_PATH,
    TEAMS_PROFILE_PATH,
    TEAMS_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH,
    TEAMS_REIMBURSEMENT_REPORTS_PATH,
    TEAMS_REIMBURSEMENT_YEARLY_SUMMARY_PATH,
    TEAMS_RH_FILES_PATH,
    TEAMS_ROLES_EDIT_PATH,
    TEAMS_ROLES_PATH,
} from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { isRhSelector } from 'selectors/role';

const TeamTabs = () => {
    const { teamId } = useExternalTeamProps();
    const {
        enableHba,
        enableRoles,
        enableViewPathwayBlueprints,
        enableViewPayrollReports,
        enableViewReimbursementReports,
        enableViewTeamBenefits,
        enableViewTeamNotes,
        enableViewTeamRhFiles,
    } = useSelector((state: AppStore) => ({
        enableHba: isRhSelector(state),
        enableRoles: hasSomePermissions(state, EditUserRoles),
        enableViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints),
        enableViewPayrollReports: hasSomePermissions(state, ViewPayrollReports),
        enableViewReimbursementReports: hasSomePermissions(state, EditSubmittedExpense),
        enableViewTeamBenefits: hasSomePermissions(state, ViewTeamBenefit),
        enableViewTeamNotes: hasSomePermissions(state, ViewTeamNotes),
        enableViewTeamRhFiles: hasSomePermissions(state, ViewTeamRhFiles),
    }));
    const data = useMemo(
        (): ITab[] => [
            {
                isVisible: true,
                TabProps: {
                    className: 'unselectable',
                    id: `team-tab-index-${-1}`,
                    label: <span>&laquo; Teams</span>,
                    to: TEAMS_PATH,
                },
            },
            {
                isVisible: true,
                possiblePaths: [TEAMS_DASHBOARD_PATH],
                TabProps: {
                    'data-cy': 'team-dashboard',
                    id: `team-tab-index-${TeamNavIndex.Dashboard}`,
                    label: 'Dashboard',
                    to: generatePath(TEAMS_DASHBOARD_PATH, { teamId }),
                },
            },
            {
                isVisible: true,
                possiblePaths: [TEAMS_PROFILE_PATH],
                TabProps: {
                    'data-cy': 'team-profile-tab',
                    id: `team-tab-index-${TeamNavIndex.TeamProfile}`,
                    label: 'Profile',
                    to: generatePath(TEAMS_PROFILE_PATH, { teamId }),
                },
            },
            {
                isVisible: true,
                possiblePaths: [TEAMS_PEOPLE_PATH, TEAMS_PEOPLE_ADD_PATH],
                TabProps: {
                    'data-cy': 'people-tab',
                    id: `team-tab-index-${TeamNavIndex.People}`,
                    label: 'Members',
                    to: generatePath(TEAMS_PEOPLE_PATH, { teamId }),
                },
            },
            {
                isVisible: enableViewTeamBenefits,
                possiblePaths: [
                    TEAMS_BENEFITS_PATH,
                    TEAM_BENEFIT_MEMBER_MANAGEMENT_PATH,
                    TEAMS_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH,
                ],
                TabProps: {
                    'data-cy': 'team-benefits-tab',
                    id: `team-tab-index-${TeamNavIndex.TeamBenefits}`,
                    label: 'Benefits',
                    to: generatePath(TEAMS_BENEFITS_PATH, { teamId }),
                },
            },
            {
                isVisible: enableViewPathwayBlueprints,
                possiblePaths: [
                    TEAMS_PATHWAY_BLUEPRINTS_PATH,
                    TEAMS_PATHWAY_BLUEPRINT_PATH,
                    TEAMS_PATHWAY_BLUEPRINT_COST_COMPARISON_PATH,
                ],
                TabProps: {
                    'data-cy': 'pathway-blueprints-tab',
                    id: `team-tab-index-${TeamNavIndex.Pathways}`,
                    label: 'Pathways',
                    to: generatePath(TEAMS_PATHWAY_BLUEPRINTS_PATH, { teamId }),
                },
            },
            {
                isVisible: enableViewPayrollReports,
                possiblePaths: [TEAMS_PAYROLL_PATH],
                TabProps: {
                    'data-cy': 'payroll-tab',
                    id: `team-tab-index-${TeamNavIndex.Payroll}`,
                    label: 'Payroll',
                    to: generatePath(TEAMS_PAYROLL_PATH, { teamId }),
                },
            },
            {
                isVisible: enableViewReimbursementReports,
                possiblePaths: [
                    TEAMS_REIMBURSEMENT_REPORTS_PATH,
                    TEAMS_REIMBURSEMENT_YEARLY_SUMMARY_PATH,
                ],
                TabProps: {
                    'data-cy': 'reimbursement-reports-tab',
                    id: `team-tab-index-${TeamNavIndex.ReimbursementReports}`,
                    label: 'Reimbursement Reports',
                    to: generatePath(TEAMS_REIMBURSEMENT_REPORTS_PATH, { teamId }),
                },
            },
            {
                isVisible: enableViewTeamRhFiles,
                possiblePaths: [TEAMS_RH_FILES_PATH],
                TabProps: {
                    'data-cy': 'files-tab',
                    id: `team-tab-index-${TeamNavIndex.Files}`,
                    label: 'Files',
                    to: generatePath(TEAMS_RH_FILES_PATH, { teamId }),
                },
            },
            {
                isVisible: enableViewTeamNotes,
                possiblePaths: [TEAMS_NOTES_PATH],
                TabProps: {
                    id: `team-tab-index-${TeamNavIndex.Notes}`,
                    label: 'Notes',
                    to: generatePath(TEAMS_NOTES_PATH, { teamId }),
                },
            },
            {
                isVisible: enableHba,
                possiblePaths: [TEAMS_HBA_PATH],
                TabProps: {
                    id: `team-tab-index-${TeamNavIndex.Hba}`,
                    label: 'HBA',
                    to: generatePath(TEAMS_HBA_PATH, { teamId }),
                },
            },
            {
                isVisible: enableRoles,
                possiblePaths: [TEAMS_ROLES_PATH, TEAMS_ROLES_EDIT_PATH],
                TabProps: {
                    'data-cy': 'team-roles-tab',
                    id: `team-tab-index-${TeamNavIndex.Roles}`,
                    label: 'Roles',
                    to: generatePath(TEAMS_ROLES_PATH, { teamId }),
                },
            },
            {
                isVisible: true,
                possiblePaths: [TEAMS_INTEGRATIONS_PATH],
                TabProps: {
                    id: `team-tab-index-${TeamNavIndex.Integrations}`,
                    label: 'Integrations',
                    to: generatePath(TEAMS_INTEGRATIONS_PATH, { teamId }),
                },
            },
        ],
        [
            teamId,
            enableViewTeamBenefits,
            enableViewPathwayBlueprints,
            enableViewPayrollReports,
            enableViewReimbursementReports,
            enableViewTeamRhFiles,
            enableViewTeamNotes,
            enableHba,
            enableRoles,
        ]
    );
    return <Tabs data={data} />;
};

export default hot(module)(TeamTabs);
