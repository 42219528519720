import Grid from '@mui/material/Grid';
import { clearImportedAgeBandedTable } from 'actions/clear';
import { IMPORT_AGE_BANDED_TABLE_ACTION } from 'actions/pathwayBlueprint/importAgeBandedTable';
import { downloadAgeBandedTableTemplate } from 'api/downloadAgeBandedTableTemplate';
import { AgeBandSizes } from 'api/generated/enums';
import FileInput from 'components/fileInput/FileInput';
import AgeBandsTable from 'components/pathwayModal/AgeBandsTable';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { IchraClassFormContext } from 'pages/pathwayBlueprint/ichraClasses/IchraClassModal';
import React, { useCallback, useContext, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue } from 'utilities/index';
import { downloadFileFromApi } from 'utilities/rhFile';

const FOUR = 4;
const SIX = 6;

const handleNewFileData = (fileData: File | File[] | undefined) => {
    switch (true) {
        case Array.isArray(fileData):
            return Array.from(fileData as File[]);
        case fileData instanceof File:
            return [fileData as File];
        default:
            return [];
    }
};

const ImportAgeBandedTableContent = () => {
    const { importedAgeBandedTableFile, setFile, ichraClass, setIchraClass } = useContext(
        IchraClassFormContext
    );
    const dispatch = useThunkDispatch();
    const { importedAgeBandedTable, isLoading } = useSelector((state: AppStore) => ({
        importedAgeBandedTable: state.importedAgeBandedTable,
        isLoading: hasApiActivity(state, IMPORT_AGE_BANDED_TABLE_ACTION),
    }));
    const newFileData: File[] = handleNewFileData(importedAgeBandedTableFile);

    useEffect(() => {
        if (
            arrayHasValue(importedAgeBandedTable.ageBandedRows) &&
            importedAgeBandedTable.errors?.length === 0
        ) {
            setIchraClass({
                ...ichraClass,
                ageBandSizeId: AgeBandSizes.Custom,
                ec64: undefined,
                ecRatio: undefined,
                ee64: undefined,
                eeRatio: undefined,
                ef64: undefined,
                efRatio: undefined,
                es64: undefined,
                esRatio: undefined,
                importedAgeBandedTable: [...importedAgeBandedTable.ageBandedRows],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importedAgeBandedTable, importedAgeBandedTableFile, isLoading, setIchraClass]);

    useEffect(
        () => () => {
            dispatch(clearImportedAgeBandedTable());
        },
        [dispatch]
    );

    const downloadAgeBandedTableTemplateFromApi = useCallback(
        async () => downloadFileFromApi(downloadAgeBandedTableTemplate),
        []
    );

    return (
        <Grid columnSpacing={2} container direction="row" item width="100%" xs={12}>
            <Grid item md={arrayHasValue(newFileData) ? FOUR : SIX} xs={12}>
                <Typography variant="h4">Import Age-Banded Table</Typography>
                <a
                    className="text-primary"
                    download="AgeBandedTableTemplate.csv"
                    onClick={downloadAgeBandedTableTemplateFromApi}
                >
                    Download Age-Banded Table Template
                </a>
            </Grid>
            {arrayHasValue(newFileData) && (
                <Grid item marginTop={2} md={4} xs={12}>
                    {newFileData.map((file) => (
                        <Tooltip
                            key={file.name}
                            style={{ cursor: 'pointer' }}
                            title="Click to remove file"
                        >
                            <i onClick={() => setFile(undefined)}>
                                <Typography variant="body2">{file.name}</Typography>
                            </i>
                        </Tooltip>
                    ))}
                </Grid>
            )}
            <Grid
                container
                item
                justifyContent="flex-end"
                md={arrayHasValue(newFileData) ? FOUR : SIX}
                xs={12}
            >
                <FileInput
                    accept={['.csv']}
                    buttonLabel="Select File"
                    disabled={isLoading}
                    fileData={newFileData}
                    hideFiles
                    onChange={(data: File | File[] | undefined) => setFile(data as File)}
                />
            </Grid>
            {arrayHasValue(ichraClass?.importedAgeBandedTable) && (
                <Grid item xs={12}>
                    <AgeBandsTable
                        isLoading={isLoading}
                        tableData={ichraClass?.importedAgeBandedTable}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default hot(module)(ImportAgeBandedTableContent);
