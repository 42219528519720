import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip, { ChipProps } from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AutoEnrollmentStatus, PlanStateIds, PlanTypeIds } from 'api/generated/enums';
import { IBasicHouseholdMemberDto, ISelectedPlan, IUser } from 'api/generated/models';
import { CreateSelectedPlans } from 'api/generated/permissions';
import ActionButtons, { IActionButtonItems } from 'components/ActionButtons';
import MediShareDisclaimerLabel from 'components/MediShareDisclaimerLabel';
import PlanStatePill from 'components/PlanStatePill';
import startCase from 'lodash/startCase';
import { RowNameValue } from 'pages/benefits/ColumnRowNameValue';
import CommonBenefitCardContent from 'pages/benefits/CommonBenefitCardContent';
import MediShareBenefitCardContent from 'pages/benefits/MediShareBenefitCardContent';
import OtherCoverageBenefitCardContent from 'pages/benefits/OtherCoverageBenefitCardContent';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { formatDateForDisplay } from 'utilities/format';
import { getCoverageForString } from 'utilities/household';
import { hasValue, IS_DEV_MODE } from 'utilities/index';
import { getTitle } from 'utilities/selectedPlan';

const getAutoEnrollmentChip = (plan: ISelectedPlan) => {
    let showAutoEnrollmentChip = plan?.isAutoEnrollEligible;
    if (!IS_DEV_MODE) {
        showAutoEnrollmentChip = showAutoEnrollmentChip && plan?.issuerName !== 'Anthem';
    }
    showAutoEnrollmentChip = showAutoEnrollmentChip || hasValue(plan?.autoEnrollmentStatus);

    if (!showAutoEnrollmentChip) {
        return <React.Fragment />;
    }
    let color: ChipProps['color'] = 'warning';
    let label = 'Auto-Enrollment Eligible';

    switch (plan?.autoEnrollmentStatus) {
        case AutoEnrollmentStatus.Ready:
            color = 'info';
            label = 'Auto-Enrollment Ready';
            break;
        case AutoEnrollmentStatus.Success:
            color = 'success';
            label = 'Auto-Enrollment Success';
            break;
        case AutoEnrollmentStatus.Failure:
            color = 'error';
            label = 'Auto-Enrollment Failure';
            break;
        default:
            break;
    }

    return <Chip color={color} label={label} size="small" sx={{ ml: 1 }} variant="outlined" />;
};

const BenefitCardContent = ({
    actionItems,
    benefit,
    householdMembers,
    showCreatedDate,
    showMemberActiveDate,
    user,
}: {
    actionItems?: IActionButtonItems;
    benefit: ISelectedPlan;
    householdMembers: IBasicHouseholdMemberDto[];
    showCreatedDate?: boolean;
    showMemberActiveDate?: boolean;
    user: IUser | undefined;
}) => {
    const { canCreatePlans } = useSelector((state: AppStore) => ({
        canCreatePlans: hasSomePermissions(state, CreateSelectedPlans),
    }));
    const { planStateId, planTypeId } = benefit;
    const isMediShare = useMemo(() => planTypeId === PlanTypeIds.MediShare, [planTypeId]);
    const getContent = () => {
        switch (benefit.planTypeId) {
            case PlanTypeIds.MediShare:
                return <MediShareBenefitCardContent benefit={benefit} />;
            case PlanTypeIds.ParentEmployer:
            case PlanTypeIds.SpouseEmployer:
                return <OtherCoverageBenefitCardContent benefit={benefit} />;
            default:
                return <CommonBenefitCardContent benefit={benefit} />;
        }
    };

    return (
        <Card>
            <CardContent>
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                    <Typography variant="h4">{getTitle(benefit)}</Typography>
                    <PlanStatePill planStateId={planStateId}>
                        {startCase(PlanStateIds[planStateId])}
                    </PlanStatePill>
                </Stack>
                <hr />
                {getContent()}
                <Grid alignItems="center" container direction="row" justifyContent="space-between">
                    <Grid item md={6}>
                        <RowNameValue
                            data={{
                                'data-cy': 'coverage-for',
                                name: 'Members Covered',
                                value: getCoverageForString(benefit, user, householdMembers),
                            }}
                            noMargin={!canCreatePlans}
                            valueClassName="text-secondary font-weight-bold"
                        />
                        {showMemberActiveDate && (
                            <RowNameValue
                                data={{
                                    'data-cy': 'active-date',
                                    name: 'Member Active Date',
                                    value: formatDateForDisplay(user?.activeDate),
                                }}
                                noMargin
                                valueClassName="text-secondary font-weight-bold"
                            />
                        )}
                        {showCreatedDate && canCreatePlans && (
                            <RowNameValue
                                data={{
                                    'data-cy': 'created-date',
                                    name: 'Record Created',
                                    value: formatDateForDisplay(benefit.createdDate),
                                }}
                                noMargin
                                rowClassName="small"
                            />
                        )}
                    </Grid>
                    {getAutoEnrollmentChip(benefit)}
                    {actionItems && (
                        <Grid className="m-2" item xs="auto">
                            <ActionButtons items={actionItems} />
                        </Grid>
                    )}
                </Grid>
                {isMediShare && <MediShareDisclaimerLabel />}
            </CardContent>
        </Card>
    );
};

export default hot(module)(BenefitCardContent);
