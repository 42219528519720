import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IReimbursementOverlayDto } from 'api/generated/models';
import DiagonalStrikethroughLine from 'components/DiagonalStrikethroughLine';
import MoopReimbursementFooter from 'components/MoopReimbursementFooter';
import { LabelValue } from 'pages/shop/PlanCard';
import React from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { getPlanTypeName } from 'utilities/plans';
import {
    shouldShowFamilyMoopWithRhReimbursement,
    shouldShowRhReimbursement,
} from 'utilities/reimbursementOverlay';

const MarketplacePlanCardContent = ({
    familyDeductibleCost,
    familyMoopCost,
    genericDrugCost,
    individualDeductibleCost,
    individualMoopCost,
    individualOnly,
    networkType,
    planName,
    primaryCareCost,
    reimbursementOverlayDto,
    specialistCost,
}: {
    familyDeductibleCost: number | undefined;
    familyMoopCost: number | undefined;
    genericDrugCost: string | undefined;
    individualDeductibleCost: number | undefined;
    individualMoopCost: number | undefined;
    individualOnly: boolean;
    networkType: string | undefined;
    planName: string | undefined;
    primaryCareCost: string | undefined;
    reimbursementOverlayDto: IReimbursementOverlayDto | undefined;
    specialistCost: string | undefined;
}) => {
    const shouldShowFamilyDeductible = hasValue(familyDeductibleCost) && !individualOnly;
    const showRhReimbursement = shouldShowRhReimbursement(reimbursementOverlayDto);
    const shouldShowFamilyMoop =
        (hasValue(familyMoopCost) && !individualOnly) ||
        shouldShowFamilyMoopWithRhReimbursement(reimbursementOverlayDto);
    return (
        <React.Fragment>
            <LabelValue label="Deductible">
                <Typography fontSize="small" fontWeight="bold">
                    {`${formatCurrency(individualDeductibleCost)} per member`}
                </Typography>
                {shouldShowFamilyDeductible && (
                    <Typography fontSize="small" fontWeight="bold">
                        {`${formatCurrency(familyDeductibleCost)} per family`}
                    </Typography>
                )}
            </LabelValue>
            <Box>
                <LabelValue label="Max Out Of Pocket">
                    <DiagonalStrikethroughLine isEnabled={showRhReimbursement}>
                        <Typography fontSize="small" fontWeight="bold">
                            {`${formatCurrency(individualMoopCost)} per member`}
                        </Typography>
                        {shouldShowFamilyMoop && (
                            <Typography fontSize="small" fontWeight="bold">
                                {`${formatCurrency(familyMoopCost)} per family`}
                            </Typography>
                        )}
                    </DiagonalStrikethroughLine>
                    {showRhReimbursement && (
                        <MoopReimbursementFooter
                            familyMoopCost={familyMoopCost}
                            individualMoopCost={individualMoopCost}
                            planName={planName}
                            reimbursementOverlayDto={reimbursementOverlayDto}
                        />
                    )}
                </LabelValue>
            </Box>
            <LabelValue label="Network Type">
                {typeof networkType === 'string' ? networkType : getPlanTypeName(networkType)}
            </LabelValue>
            <LabelValue label="Primary Care Visit">{primaryCareCost}</LabelValue>
            <LabelValue label="Specialist Visit">{specialistCost}</LabelValue>
            <LabelValue label="Generic Drugs">{genericDrugCost}</LabelValue>
        </React.Fragment>
    );
};

export default hot(module)(MarketplacePlanCardContent);
