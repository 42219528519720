import { PlanStateIds } from 'api/generated/enums';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

export const PlanStatePill = styled.div.attrs<{ planStateId: PlanStateIds }>(({ planStateId }) => {
    const notTerminatedColor = planStateId === PlanStateIds.Effective ? 'success' : 'info';
    const color = planStateId === PlanStateIds.Terminated ? 'dark' : notTerminatedColor;
    return {
        className: `text-${color} bg-${color}-90-percent-transparent float-right`,
    };
})<{ planStateId: PlanStateIds }>`
    border-radius: 14px;
    width: 151px;
    text-align: center;
    line-height: 26px;
    font-size: 12px;
`;

export default hot(module)(PlanStatePill);
