import { clearMarketplacePlans } from 'actions/clear';
import {
    LIST_DETAILS_FOR_PLANS_FOR_HOUSEHOLD_ACTION,
    listDetailsForHousehold,
} from 'actions/marketplacePlan/listDetailsForHousehold';
import { GET_MEDISHARE_PLANS_FOR_USER_ACTION } from 'actions/medishare/getMediSharePlansAndRatesForUser';
import { PlanTypeIds } from 'api/generated/enums';
import {
    IMarketplacePlanDto,
    ISelectedPlan,
    Issuer,
    PlanDetailsAndRatesRequest,
} from 'api/generated/models';
import CommonPlanInputs from 'components/planModal/CommonPlanInputs';
import { IGetCommonPropsPlanInputs } from 'components/planModal/PlanInputs';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import TextField from 'components/TextField';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import React, { useCallback, useEffect, useMemo } from 'react';
import Alert from 'react-bootstrap/Alert';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { uniqueMarketplaceCarriersSelector } from 'selectors/marketplace';
import { hasValue } from 'utilities';
import { getPlansByCarrier } from 'utilities/marketplacePlan';

const ONE_THOUSAND_MILLISECONDS = 1000;
const MarketplacePlanInputs = ({
    getCommonProps,
    planToEdit,
}: {
    getCommonProps: IGetCommonPropsPlanInputs;
    planToEdit: ISelectedPlan | undefined;
}) => {
    const dispatch = useThunkDispatch();
    const isEdit = !!planToEdit?.selectedPlanId;
    const { userId } = useUserProps();
    const {
        carriers,
        exchange,
        householdMemberIdsForSelectedYear,
        isLoading,
        planInputs,
        plansByCarrier,
        selectedHouseholdMemberIds,
    } = useSelector((state: AppStore) => ({
        carriers: uniqueMarketplaceCarriersSelector(state),
        exchange: state.planModalState.planInputs.exchange,
        householdMemberIdsForSelectedYear: state.householdMembers
            .filter((x) => x.haveDataForYear)
            .map((x) => x.householdMemberId)
            .concat(userId),
        isLoading: hasApiActivity(
            state,
            LIST_DETAILS_FOR_PLANS_FOR_HOUSEHOLD_ACTION,
            GET_MEDISHARE_PLANS_FOR_USER_ACTION
        ),
        planInputs: state.planModalState.planInputs,
        plansByCarrier: getPlansByCarrier(
            state.marketplacePlans,
            state.planModalState.planInputs.selectedCarrier
        ),
        selectedHouseholdMemberIds: state.planModalState.selectedHouseholdMemberIds,
    }));
    useEffect(() => {
        dispatch(clearMarketplacePlans());
    }, [dispatch]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceListDetailsForHouseholdWithFallback = useCallback(
        debounce((id: string, excludingMembers: string[], year: number, isOffExchange: boolean) => {
            dispatch(clearMarketplacePlans());
            dispatch(
                listDetailsForHousehold(
                    id,
                    new PlanDetailsAndRatesRequest({
                        excludingMembers,
                        isOffExchange,
                        year,
                        overrideNeedsCoverage: true,
                    })
                )
            );
        }, ONE_THOUSAND_MILLISECONDS),
        []
    );
    useEffect(() => {
        if (hasValue(planInputs.selectedYear) && selectedHouseholdMemberIds.length > 0 && !isEdit) {
            debounceListDetailsForHouseholdWithFallback(
                userId,
                difference(householdMemberIdsForSelectedYear, selectedHouseholdMemberIds),
                parseInt(planInputs.selectedYear),
                planInputs.planType === PlanTypeIds.OffExchange
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, planInputs.selectedYear, planInputs.planType, selectedHouseholdMemberIds]);
    const showMarketplaceInputs = useMemo(
        () => hasValue(planInputs.selectedYear) && selectedHouseholdMemberIds.length > 0,
        [planInputs.selectedYear, selectedHouseholdMemberIds.length]
    );
    const showExchange = useMemo(() => planInputs.planType === PlanTypeIds.Marketplace, [
        planInputs.planType,
    ]);
    return (
        <React.Fragment>
            {!isEdit && (
                <Alert variant="info">
                    The Year determines what plan and user data we use.{' '}
                    <strong>
                        Ensure this user has at least an address for the selected Year above to get
                        plans.
                    </strong>{' '}
                    For more accurate data ensure the user has verified info and a Wage+ or
                    Reimbursement set for that year as well.
                </Alert>
            )}
            {selectedHouseholdMemberIds.length > 0 && (
                <CommonPlanInputs isEndDateRequired isStartDateRequired />
            )}
            {showMarketplaceInputs && (
                <Skeleton count={3} height="54px" isEnabled={isLoading}>
                    {showExchange && (
                        <TextField
                            data-cy="exchange"
                            disabled
                            label="Exchange"
                            name="exchange"
                            value={`${exchange} Exchange`}
                        />
                    )}
                    <Select
                        {...getCommonProps('Marketplace Carrier', 'selectedCarrier')}
                        data-cy="marketplace-provider"
                        defaultText="Choose a Carrier"
                        defaultValue=""
                        disabled={isEdit}
                        items={isEdit ? [new Issuer({ name: planToEdit.issuerName })] : carriers}
                        optionText="name"
                        optionValue="name"
                    />
                    <Select
                        {...getCommonProps('Plan Name', 'selectedMarketplacePlan')}
                        data-cy="plan-name"
                        defaultText="Choose a Plan"
                        defaultValue=""
                        disabled={isEdit}
                        items={
                            isEdit
                                ? ([
                                      {
                                          id: planToEdit.planId,
                                          name: planToEdit.planName,
                                      },
                                  ] as IMarketplacePlanDto[])
                                : plansByCarrier
                        }
                        optionText="name"
                        optionValue="id"
                    />
                </Skeleton>
            )}
        </React.Fragment>
    );
};

export default hot(module)(MarketplacePlanInputs);
